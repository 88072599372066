/* eslint-disable react/forbid-elements */
// Disabling the rule to remove keyboard navigation focus ring that ds2 Link element had
import React from 'react';

import { Avatar } from '@change/tomato-core-ui';

import { Badge } from '../Badge';

import { Footer } from './components/Footer';
import { useTopImpactStoriesMobile } from './hooks/useTopImpactStoriesMobile';

export const TopImpactStoriesMobile: React.FC = () => {
	const {
		data: { carouselStories, currentStoryIndex, containerRef, storyRefs },
		actions: { genDataTestId, trackStoryClick, onStoryFocus, onScroll },
	} = useTopImpactStoriesMobile();

	return (
		<div className="relative z-10 lg:hidden">
			<div
				className="scroll-snap-x scroll-snap-mandatory mb-2 flex snap-x snap-mandatory gap-20 overflow-x-auto pb-2"
				data-testid="top-impact-stories-mobile-container"
				ref={containerRef}
				// eslint-disable-next-line react/forbid-dom-props
				style={{
					WebkitOverflowScrolling: 'touch',
					scrollbarWidth: 'none',
					msOverflowStyle: 'none',
				}}
				onScroll={onScroll}
			>
				<div aria-live="polite" className="sr-only" tabIndex={-1}>
					{carouselStories[currentStoryIndex].ariaLabel}
				</div>
				{carouselStories.map((story, index) => (
					<div
						key={`${story.key}-mobile`}
						className="flex flex-shrink-0 snap-center flex-col items-center justify-center py-8"
						ref={(el) => (storyRefs.current[index] = el)}
					>
						<div className="group relative h-[180px] w-[180px]">
							<div
								className={`absolute inset-[-10px] z-0 rounded-full transition-all duration-300 ease-in-out will-change-transform ${
									index === currentStoryIndex ? 'opacity-100' : 'opacity-0'
								}`}
							>
								<div className="h-full w-full rounded-full bg-surface-accent-secondary-pressed blur-lg filter" />
							</div>

							<a
								href={story.url}
								className="z-10 block h-full w-full focus-visible:outline-transparent"
								onClick={() => trackStoryClick(story.url)}
								aria-label={story.ariaLabel}
								data-testid={genDataTestId(story.key, 'link')}
								onFocus={() => onStoryFocus(index)}
								tabIndex={story.tabIndex}
								aria-hidden={story.ariaHidden}
							>
								<div className="relative h-full w-full overflow-visible">
									<div className="h-full w-full overflow-hidden rounded-full">
										<Avatar
											src={story.image}
											alt={story.altText}
											className="-webkit-backface-visibility-hidden h-full w-full object-cover"
											data-testid={genDataTestId(story.key, 'image')}
											aria-hidden
										/>
									</div>
								</div>
							</a>

							<div
								data-testid={genDataTestId(story.key, 'badge')}
								className="absolute left-1/2 top-full z-20 -translate-x-1/2 transform"
								aria-hidden
							>
								<Badge labelTitle={story.labelTitle} labelText={story.labelText} />
							</div>
						</div>
					</div>
				))}
			</div>

			<Footer dataTestId={genDataTestId(carouselStories[currentStoryIndex].key, 'summary')} />
		</div>
	);
};
