import type { ReadonlyDeep } from 'type-fest';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { HomepageData, type HomepageDataQuery, type HomepageDataQueryVariables } from './api.graphql';
import { MARQUEE_ITEMS } from './constants';
import type { ImpactStory } from './getTopImpactStories';
import { getTopImpactStories } from './getTopImpactStories';
import { HomepagePageDataError } from './types';

type TrendingPetition = NonNullable<HomepageDataQuery['trendingPetitions']>['edges'][number];

export type HomepagePageData = ReadonlyDeep<{
	trendingPetitions: TrendingPetition[];
	totalUserCount: HomepageDataQuery['totalUserCount'];
	trendingTopics: HomepageDataQuery['trendingTopics']['featuredTopics'];
}> & { marqueeItems: typeof MARQUEE_ITEMS } & {
	v3TopImpactStories: ImpactStory[];
};

type HomepagePageDataParams = { locale: string; countryCode: string };

// eslint-disable-next-line complexity
export async function getHomepagePageData(
	utilityContext: UtilityContext,
	{ countryCode, locale }: HomepagePageDataParams,
): Promise<HomepagePageData> {
	const {
		gql: { fetch },
		errorReporter,
	} = utilityContext;
	const { data } = await fetch<HomepageDataQuery, HomepageDataQueryVariables>({
		query: HomepageData,
		variables: {
			locale,
			countryCode,
		},
		rejectOnError: false,
	});

	if (!data) {
		// No data returned
		const error = new HomepagePageDataError('homepage_v2_page_data_not_found');
		void errorReporter.report(error);
		throw error;
	}

	if (data.trendingPetitions == null || !data.trendingPetitions.edges) {
		// No featured petition data
		void errorReporter.report(
			new HomepagePageDataError('homepage_v2_page_data_not_found', {
				message: 'No trending petition data returned',
			}),
		);
	}

	const { success, status } = data.trendingTopics || {};
	if (!data.trendingTopics || !success) {
		// No featured topic data
		const errorMessage = status
			? `Unknown error calling getFeaturedTopicsByLocale query (status: ${status})`
			: 'Unknown error calling getFeaturedTopicsByLocale query';
		void errorReporter.report(new HomepagePageDataError('homepage_v2_page_data_not_found', { message: errorMessage }));
	}

	const v3TopImpactStories = await getTopImpactStories({ locale, utilityContext });

	return {
		trendingPetitions: data.trendingPetitions?.edges ?? [],
		trendingTopics: data.trendingTopics.featuredTopics ?? [],
		totalUserCount: data.totalUserCount,
		marqueeItems: MARQUEE_ITEMS,
		v3TopImpactStories,
	};
}
