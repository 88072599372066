/* eslint-disable react/forbid-elements */
// Disabling the rule to remove keyboard navigation focus ring that ds2 Link element had
import React from 'react';

import { Avatar, Text } from '@change/tomato-core-ui';

import { Badge } from '../Badge';
import { useTopImpactStories } from '../shared/hooks/useTopImpactStories';

export const TopImpactStoriesDesktop: React.FC = () => {
	const {
		data: { topStories },
		actions: { genDataTestId, trackStoryClick },
	} = useTopImpactStories();

	return (
		<div className="hidden grid-cols-5 lg:grid" data-testid="top-impact-stories-container">
			{topStories.map((story) => (
				<div key={story.key} className="flex flex-col items-center justify-center">
					{/* HOVER ANIMATION */}
					<div className={`absolute ${story.top} ${story.transform} h-[180px] w-[180px]`}>
						<div className="group relative h-full w-full">
							<div className="absolute inset-[-10px] z-0 rounded-full opacity-0 transition-all duration-300 ease-in-out will-change-transform group-focus-within:opacity-100 group-hover:opacity-100">
								<div className="h-full w-full rounded-full bg-surface-accent-secondary-pressed blur-lg filter" />
							</div>

							{/* LINKED AVATAR */}
							<a
								href={story.url}
								className="z-10 block h-full w-full focus-visible:outline-transparent"
								onClick={() => trackStoryClick(story.url)}
								aria-label={story.ariaLabel}
								data-testid={genDataTestId(story.key, 'link')}
							>
								<div className="relative h-full w-full overflow-visible">
									<div className="h-full w-full overflow-hidden rounded-full">
										<Avatar
											src={story.image}
											alt={story.altText}
											className="-webkit-backface-visibility-hidden h-full w-full object-cover transition-transform duration-300 ease-in-out group-focus-within:scale-[1.1] group-hover:scale-[1.1]"
											data-testid={genDataTestId(story.key, 'image')}
										/>
									</div>
								</div>
							</a>

							{/* BADGE */}
							<div
								data-testid={genDataTestId(story.key, 'badge')}
								className="absolute left-1/2 top-full z-20 -translate-x-1/2 transform"
							>
								<Badge labelTitle={story.labelTitle} labelText={story.labelText} />
							</div>
							<div className="font-weight-light absolute left-1/2 top-full z-20 mt-10 w-60 -translate-x-1/2 transform text-center">
								<Text
									className="line-clamp-4 overflow-hidden text-ellipsis opacity-0 transition-opacity duration-300 group-focus-within:opacity-100 group-hover:opacity-100"
									data-testid={genDataTestId(story.key, 'summary')}
								>
									{story.summary}
								</Text>
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};
