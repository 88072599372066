import { useTracking } from '@change-corgi/core/react/tracking';

import { useHomepagePageData } from 'src/app/pages/homepageV2/pageContext';

export type TopStories = {
	key: number;
	image: string;
	altText: string;
	ariaLabel: string;
	url: string;
	summary: string;
	top: string;
	transform?: string;
	labelTitle: string;
	labelText: string;
};
type Result = ModelHookResult<
	{
		topStories: TopStories[];
	},
	{
		trackStoryClick: (url: string) => void;
		genDataTestId: (key: number, component: string) => string;
	}
>;

// TODO: Scale calculation of the yAxis and transform values to non-5 stories.
// Hardcoded topStories array for now, with data working for 5 stories.
const getPositionClasses = (index: number) => {
	switch (index) {
		case 1:
			return {
				top: 'top-[360px]',
				transform: 'translate-x-[-60%] translate-y-[-10%]',
			};
		case 2:
			return {
				top: 'top-[420px]',
			};
		case 3:
			return {
				top: 'top-[360px]',
				transform: 'translate-x-[60%] translate-y-[-10%]',
			};
		default:
			return {
				top: 'top-[20px]',
			};
	}
};

export function useTopImpactStories(): Result {
	const track = useTracking();
	const {
		data: { v3TopImpactStories },
	} = useHomepagePageData();

	const totalStories = v3TopImpactStories.length ?? 0;

	const getLabelText = (signatureCount: string): string => {
		return signatureCount === '1' ? `${signatureCount} signature` : `${signatureCount} signatures`;
	};

	const topStories = (v3TopImpactStories ?? []).map((story, index) => ({
		key: index + 1,
		image: story.imageUrl,
		altText: `Photo of impact story ${index + 1} of ${totalStories}`,
		ariaLabel: `Top impact story ${index + 1} of ${totalStories}. ${story.badgeTitle} ${getLabelText(story.petition.signatureCount)}. ${story.summary}`,
		url: story.testimonialUrl || `/p/${story.petition.slug}`,
		summary: story.summary,
		labelTitle: story.badgeTitle,
		labelText: getLabelText(story.petition.signatureCount),
		...getPositionClasses(index),
	}));

	return {
		data: {
			topStories,
		},
		actions: {
			trackStoryClick: (url: string) => {
				void track('homepage_top_impact_story_click', { url });
			},
			genDataTestId: (key: number, component: string) => {
				return `top-impact-story-${key}-of-${topStories.length}-${component}`;
			},
		},
	};
}
